<template>
  <v-card class="rounded-xl">
    <v-card-title>
      <h2 class="subtitle-1">{{ truck.meta.id }}: {{ truck.meta.nickname }}</h2>
      <v-spacer></v-spacer>
      <v-btn
        :href="truck.meta.admin_url"
        target="_blank"
        color="blue white--text"
        class="rounded-lg"
        >More Info</v-btn
      >
    </v-card-title>

    <v-card-text>
      <v-alert
        type="error"
        v-for="warning in criticalWarnings"
        :key="warning.id"
      >
        {{ warning.text }}
      </v-alert>
      <v-alert
        type="warning"
        v-for="warning in nonCriticalWarnings"
        :key="warning.id"
      >
        {{ warning.text }}
      </v-alert>
      <p class="d-flex">
        Last Seen <v-spacer></v-spacer
        ><span v-if="truck.meta.last_activity" class="font-weight-black">{{
          truck.meta.last_activity | moment("MMM D, YYYY [@] HH:mm")
        }}</span>
      </p>
      <p class="d-flex">
        Company <v-spacer></v-spacer
        ><span class="font-weight-black">{{ truck.meta.company }}</span>
      </p>
      <p class="d-flex">
        Primary Contact <v-spacer></v-spacer
        ><span class="font-weight-black">{{ truck.meta.contact_name }}</span>
      </p>
      <p class="d-flex">
        Phone No. on File <v-spacer></v-spacer
        ><span class="font-weight-black">{{
          truck.meta.contact_mobile || truck.meta.contact_office
        }}</span>
      </p>
      <p class="d-flex">
        Plate / VIN <v-spacer></v-spacer
        ><span class="font-weight-black">{{ truck.meta.plate }}</span>
      </p>
      <p class="d-flex">
        Environment <v-spacer></v-spacer
        ><span class="font-weight-black">{{ truck.meta.environment }}</span>
      </p>
      <p class="d-flex">
        Device ID <v-spacer></v-spacer
        ><span class="font-weight-black">{{
          truck.device && truck.device.device_id
        }}</span>
      </p>
      <p class="d-flex">
        Version <v-spacer></v-spacer
        ><span class="font-weight-black">{{
          truck.device && truck.device.version
        }}</span>
      </p>
      <p class="d-flex">
        Base Version <v-spacer></v-spacer
        ><span class="font-weight-black">{{
          truck.device && truck.device.base_version
        }}</span>
      </p>
      <p class="d-flex">
        Overnight <v-spacer></v-spacer><span class="font-weight-black"></span>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["truck"],
  computed: {
    criticalWarnings() {
      return this.truck.warnings.filter((warn) => warn.severity === "Critical");
    },
    nonCriticalWarnings() {
      return this.truck.warnings.filter((warn) => warn.severity === "Warning");
    },
  },
  watch: {
    truck: {
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
  },
};
</script>
